import React from "react";
import Seo from "./seo/Seo";
const Required = () => {
  return <span className="text-red-500">*</span>;
};

const commonNames = [
  "Chicken",
  "Cattle",
  "Pig",
  "Tuna",
  "Salmon",
  "Exotic/Other",
];
const cellTypes = [
  "Myosatellite Cells",
  "Adipose-derived Stem Cells",
  "iPSCs for muscle growth",
  "iPSCs for fat growth",
];
const cellLineTypes = ["Wild-type", "Selected", "Engineered"];

const FieldSet = ({ i, l, p }) => {
  return (
    <fieldset>
      <legend className="text-base font-medium text-slate-900">
        {l}
        <span className="text-red-500">*</span>
      </legend>

      <p className="text-sm text-slate-500">{p}</p>
      <div className="mt-4 space-y-4">
        {i.map((v) => (
          <div className="flex items-start" key={v}>
            <div className="flex items-center h-5">
              <input
                id={v}
                name={`Cell Types: ${v}`}
                type="checkbox"
                value={v}
                className="w-4 h-4 text-blue-600 rounded border-slate-300 focus:ring-blue-500"
              />
            </div>
            <div className="ml-3 text-sm">
              <label htmlFor={v} className="font-medium text-slate-700">
                {v}
              </label>
            </div>
          </div>
        ))}
      </div>
    </fieldset>
  );
};

export default function WaitlistForm() {
  return (
    <>
      <Seo title="Join the Cells Waitlist" />
      <form
        method="POST"
        netlify-honeypot="bot-field"
        data-netlify="true"
        name="waitlist"
      >
        <input type="hidden" name="bot-field" aria-hidden="true" />
        <input
          type="hidden"
          name="form-name"
          value="waitlist"
          aria-hidden="true"
        />
        <div>
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h2 className="text-lg font-medium leading-6 text-slate-200">
                  Company
                </h2>
                <p className="mt-1 text-sm text-slate-400">
                  General company information. For startups without a public
                  presence you can enter your temporary domain or "stealth.com".
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div>
                <div className="rounded-lg md:overflow-hidden">
                  <div className="px-4 py-5 space-y-6 bg-white sm:p-6">
                    <div className="grid grid-cols-3 gap-6">
                      <div className="col-span-3 sm:col-span-2">
                        <label
                          htmlFor="company-website"
                          className="block text-sm font-medium text-slate-700"
                        >
                          Company Website
                          <Required />
                        </label>
                        <div className="flex mt-1 border rounded-md ">
                          <span className="inline-flex items-center px-3 text-sm border-r-0 text-slate-500 border-slate-300 rounded-l-md bg-slate-50">
                            https://
                          </span>
                          <input
                            type="text"
                            name="company-website"
                            id="company-website"
                            className="flex-1 block w-full p-2 rounded-none border-slate-300 focus:ring-blue-500 focus:border-blue-500 rounded-r-md sm:text-sm"
                            placeholder="www.company.com"
                            autoComplete="url"
                            required
                            aria-required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-span-6 sm:col-span-3">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-slate-700"
                      >
                        Country
                      </label>
                      <select
                        id="country"
                        name="country"
                        autoComplete="country-name"
                        className="block w-full px-3 py-2 mt-1 bg-white border rounded-md shadow-sm border-slate-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      >
                        <option>United States</option>
                        <option>Australia</option>
                        <option>China</option>
                        <option>Israel</option>
                        <option>Netherlands</option>
                        <option>Singapore</option>
                        <option>Switzerland</option>
                        <option>United Kingdom</option>
                        <option>Other</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-slate-200" />
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h2 className="text-lg font-medium leading-6 text-slate-200">
                  Contact
                </h2>
                <p className="mt-1 text-sm text-slate-400">
                  Your contact information or the best contact in your company.
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div>
                <div className="rounded-lg md:overflow-hidden">
                  <div className="px-4 py-5 bg-white sm:p-6">
                    <div className="grid grid-cols-6 gap-6">
                      <div className="col-span-6 sm:col-span-4">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-slate-700"
                        >
                          Name
                          <Required />
                        </label>
                        <input
                          type="text"
                          name="name"
                          id="name"
                          autoComplete="name"
                          required
                          aria-required
                          className="block w-full p-2 mt-1 border rounded-md shadow-sm border-slate-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          placeholder="Avery Walker"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-4">
                        <label
                          htmlFor="email-address"
                          className="block text-sm font-medium text-slate-700"
                        >
                          Email
                          <Required />
                        </label>
                        <input
                          type="email"
                          name="email-address"
                          id="email-address"
                          autoComplete="email"
                          required
                          aria-required
                          className="block w-full p-2 mt-1 border rounded-md shadow-sm border-slate-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          placeholder="avery.walker@company.com"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-4">
                        <label
                          htmlFor="phone-number"
                          className="block text-sm font-medium text-slate-700"
                        >
                          Phone Number
                        </label>
                        <input
                          type="tel"
                          name="phone-number"
                          id="phone-number"
                          autoComplete="tel"
                          className="block w-full p-2 mt-1 border rounded-md shadow-sm border-slate-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          placeholder="+1 (415) 989-7890"
                        />

                        <p className="mt-1 text-sm text-slate-400">
                          Formatting isn't required.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-slate-200" />
          </div>
        </div>

        <div className="mt-10 sm:mt-0">
          <div className="md:grid md:grid-cols-3 md:gap-6">
            <div className="md:col-span-1">
              <div className="px-4 sm:px-0">
                <h2 className="text-lg font-medium leading-6 text-slate-200">
                  Cell Lines
                </h2>
                <p className="mt-1 text-sm text-slate-400">
                  Select all of the cell line lines that you are interested in.
                  If you're interested in any exotic lines or custom lines,
                  please let us know.
                </p>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:col-span-2">
              <div>
                <div className="rounded-lg md:overflow-hidden">
                  <div className="px-4 py-5 space-y-6 bg-white sm:p-6">
                    <FieldSet i={commonNames} l="Species (common name)" />
                    <FieldSet i={cellTypes} l="Cell Types" />
                    <FieldSet i={cellLineTypes} l="Cell Line Types" />

                    <div>
                      <label
                        htmlFor="about"
                        className="block text-sm font-medium text-slate-700"
                      >
                        Interested in a species or cell type that isn't listed?
                      </label>

                      <div className="mt-1">
                        <textarea
                          id="about"
                          name="about"
                          rows={3}
                          className="block w-full p-2 mt-1 border rounded-md shadow-sm border-slate-300 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                          placeholder="Kangaroo, fibroblasts, mesenchymal stem cells, fibro-adipogenic progenitors"
                          defaultValue={""}
                        />
                      </div>

                      <p className="mt-1 text-sm text-slate-400">
                        List all additional cell line interests.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-full col-span-2 col-start-2 px-4 mt-2 mb-8 md:px-0 md:mt-0 md:mb-0">
              <button
                type="submit"
                className="w-full px-4 py-2 mt-4 text-lg text-white bg-blue-500 rounded-full hover:bg-blue-600 "
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
