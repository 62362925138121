import React from "react";
import WaitlistForm from "../components/WaitlistForm";

export default function Waitlist() {
  return (
    <main className="flex flex-col w-full min-h-screen bg-slate-900 ">
      <div className="p-4 my-4 xl:container xl:mx-auto xl:my-8 xl:p-0">
        <h1 className="text-2xl font-semibold tracking-tight lg:text-4xl lg:mb-8 md:ml-0 text-slate-100 ">
          Join the Cultura Waitlist
        </h1>
      </div>
      <section className="container w-full h-full pb-8 mx-auto rounded-lg bg-slate-900 md:px-4 xl:px-0">
        <WaitlistForm />
      </section>
    </main>
  );
}
